<template>
  <div class="text-left">
    <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group id="post-group">
        <b-form-input
          id="title"
          v-model="form.title"
          type="text"
          required
          placeholder="Заголовок"
        />
      </b-form-group>

      <b-input-group v-show="form.id" class="mb-3">
        <b-form-input
          id="date"
          v-model="form.date"
          type="text"
          placeholder="YYYY-MM-DD"
          autocomplete="off"
        />
        <b-input-group-append>
          <b-form-datepicker
            v-model="form.date"
            button-only
            right
            aria-controls="date"
          />
        </b-input-group-append>
      </b-input-group>

      <b-form-group id="tags-group">
        <multiselect
          v-model="form.tags"
          tag-placeholder="Добавить это как новый тег"
          placeholder="Поиск или добавление тега"
          label="name"
          track-by="code"
          :options="form.tags_options"
          :multiple="true"
          :taggable="true"
          @tag="addTag"
        >
          variant="primary"
        </multiselect>
      </b-form-group>

      <b-form-group id="text-group">
        <Editor
          id="tinymce"
          v-model="form.text"
          :init="tinyMceInit"
          api-key="0gsjqp7me88za2dlmjxw9xrgnd7kiq8ejxo6tunbol5g324m"
        />
      </b-form-group>

      <b-form-group id="checkbox-group">
        <b-form-checkbox
          id="suggest-checkbox"
          v-model="form.suggest"
        >
          Предлагать этот пост к просмотру
        </b-form-checkbox>
      </b-form-group>

      <b-form-group id="buttons-group" class="mt-2">
        <b-button type="submit" variant="primary">
          {{ form.id ? "Применить" : "Сохранить" }}
        </b-button>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    Multiselect,
    Editor,
  },
  props: ['postId'],
  data() {
    return {
      form: {
        id: null,
        title: '',
        date: '',
        text: '',
        suggest: true,
        images: null,
        tags: [],
        tags_options: [],
      },

      answer: {
        id: null,
        title: null,
        text: null,
        suggest: true,
        date: new Date().toISOString().substring(0, 10),
        images: null,
        tags: [],
        tags_options: [],
      },
      files: [],
      images: [],
      imagePreview: '',
      showPreview: false,
      tinyMceInit: {
        language: 'ru',
        height: 370,
        plugins: ['lists link image paste'],
        toolbar: 'bold italic image blockquote numlist bullist',
        images_upload_handler: this.uploadImage,
      },
      axiosConfig: {},
    }
  },
  computed: {
    thumbnails() {
      return this.images.map((image, index) => {
        return { image: image.image, id: index }
      })
    },
  },
  mounted() {
    if (this.postId !== null) {
      this.form.id = this.postId
      this.getPost()
    } else {
      this.form.id = null
      this.getTagsList()
    }

    this.setFormToAnswer()

    this.axiosConfig = this.$store.getters.getAxiosConfig
    this.axiosConfig.headers['Content-Type'] = 'multipart/form-data'
  },
  methods: {
    closeModal(updated) {
      this.$emit('closePostModal', updated)
    },
    uploadImage(blobInfo, success, failure) {
      const formData = new FormData()
      formData.append('img', blobInfo.blob(), blobInfo.filename())
      this.axios
        .post(
          process.env.VUE_APP_BLOG_BACKEND_URL + '/blog/image',
          formData,
          this.axiosConfig,
        )
        .then(response => {
          if (response) {
            if (response.data.error === false) {
              success(response.data.url)
            } else {
              failure('Ошибка загрузки', true)
            }
          }
        })
        .catch(() => {
          failure('Ошибка загрузки', true)
        })
    },
    onSubmit(evt) {
      evt.preventDefault()
      if (this.form.text !== null && this.form.text.trim() !== '') {
        this.form.id !== null ? this.updatePost() : this.insertPost()
      } else
        this.$bvToast.toast('Пост не может быть пустым.', {
          title: 'Внимание!',
          variant: 'warning',
          solid: true,
        })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      this.setFormToAnswer()
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      }
      this.form.tags_options.push(tag)
      this.form.tags.push(tag)
    },
    async getPost() {
      this.axios
        .get(
          process.env.VUE_APP_BLOG_BACKEND_URL + `/blog/post/${this.form.id}`,
          this.$store.getters.getAxiosConfig,
        )
        .then(response => {
          this.answer.id = response.data.id
          this.answer.title = response.data.title
          this.answer.text = response.data.text
          this.answer.suggest = response.data.suggest
          this.answer.date = response.data.date
          this.answer.images = response.data.images
          this.answer.tags = response.data.tags ? response.data.tags : []
          this.answer.tags_options = response.data.tags_options
            ? response.data.tags_options
            : []
          this.getImages()
        })
        .catch(error => {
          console.error(error)
        })
    },
    async getTagsList() {
      this.axios
        .get(
          process.env.VUE_APP_BLOG_BACKEND_URL + '/blog/listTeg',
          this.$store.getters.getAxiosConfig,
        )

        .then(item => {
          this.answer.tags_options = item.data.tags_options
            ? item.data.tags_options
            : []
        })
        .catch(error => {
          console.error(error)
        })
    },
    async insertPost() {
      let formData = new FormData()
      let images = []
      let index = 0
      this.images.map(image => {
        if (image.file) {
          formData.append('file' + index, image.file)
          index++
        }

        images.push({
          path: image.path,
          title: image.title,
          originalFileName: image.originalFileName,
        })
      })

      formData.append('images', JSON.stringify({ images: images }))
      formData.append('form', JSON.stringify(this.form))

      this.axios
        .post(
          process.env.VUE_APP_BLOG_BACKEND_URL + '/blog/post/create',
          formData,
          this.axiosConfig,
        )
        .then(() => {
          this.closeModal(true)
        })
    },
    async updatePost() {
      let formData = new FormData()
      let images = []
      let index = 0
      this.images.map(image => {
        if (image.file) {
          formData.append('file' + index, image.file)
          index++
        }

        images.push({
          path: image.path,
          title: image.title,
          originalFileName: image.originalFileName,
        })
      })

      formData.append('images', JSON.stringify({ images: images }))
      formData.append('form', JSON.stringify(this.form))

      this.axios
        .post(
          process.env.VUE_APP_BLOG_BACKEND_URL +
            `/blog/post/update/${this.form.id}`,
          formData,
          this.axiosConfig,
        )
        .then(() => {
          this.closeModal(true)
        })
        .catch(function() {
          console.log('FAILURE!!')
        })
    },
    setFormToAnswer() {
      this.form = this.answer
    },
    async addFile() {
      if (this.files) {
        Array.from(this.files).map(item => {
          if (/\.(jpe?g|png|gif)$/i.test(item.name)) {
            const result = {
              image: '',
              file: null,
              path: null,
              title: '',
              originalFileName: item.name,
            }
            this.images.push(result)
            let index = this.images.length - 1
            let reader = new FileReader()

            reader.onloadend = () => {
              this.images[index].image = reader.result
              this.images[index].file = item
            }

            reader.readAsDataURL(item)
          }
        })
      }
    },
    async getImages() {
      this.form.images.map(item => {
        const result = {
          image: process.env.VUE_APP_IMAGES_URL_PREFIX + item.filename,
          file: null,
          path: item.filename,
          title: item.title,
        }
        this.images.push(result)
      })
    },
    images_delete(id) {
      this.images.splice(id, 1)
    },
  },
}
</script>

<style>
#image-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

#image-list div {
  width: 300px;
  height: auto;
  margin: 10px;
}

#image-list div div.image-wrap {
  width: 300px;
  height: 200px;
  overflow: hidden;
  border: #2c3e50 solid 1px;
  border-radius: 3px;
}

#image-list div img {
  width: 300px;
  height: auto;
}

#image-list div input {
  margin-left: 10px;
}

#image-list button {
  display: block;
  float: right;
}
</style>
