<template>
  <div class="text-left">
    <div v-if="isLoading" class="text-center text-primary my-2">
      <b-spinner class="align-middle" />
      <strong class="ml-3">Загрузка данных...</strong>
    </div>
    <div v-else>
      <h2>Список постов</h2>

      <b-button class="mt-3" @click="createPost">
        Добавить
      </b-button>
      <b-modal
        id="post-modal"
        ref="post-modal"
        no-enforce-focus
        scrollable
        size="lg"
        hide-footer
        :title-html="modalTitle"
      >
        <Post :post-id="postId" @closePostModal="onClosePostModal($event)" />
      </b-modal>

      <b-table
        class="list-post"
        small
        show-empty
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
      >
        <template v-slot:cell(id)="data">
          <b-button-group>
            <b-button
              variant="primary"
              size="sm"
              @click="editPost(data.value)"
            >
              <b-icon-pencil />
            </b-button>
            <b-button
              v-b-modal.modal-delete
              variant="danger"
              size="sm"
              @click="itemToDelete = data.value"
            >
              <b-icon icon="trash-fill" aria-hidden="true" />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        size="md"
        :total-rows="totalItems"
        :per-page="perPage"
      />
      <b-modal
        id="modal-delete"
        title="Удаление поста"
        @ok="delPost(itemToDelete)"
      >
        <p class="alert-warning">
          После удаления поста, восстановить его содержимое будет не возможно!
        </p>
        <p>Вы действительно желаете удалить пост?</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Post from '../components/Post'
export default {
  name: 'Posts',
  components: {
    Post,
  },
  data() {
    return {
      postId: 8,
      isLoading: false,
      items: [],
      itemToDelete: null,
      fields: [
        {
          key: 'title',
          label: 'Заголовок',
        },
        {
          key: 'date',
          label: 'Дата публикации',
          formatter: 'datePost',
          class: 'blog-list-data',
        },
        {
          key: 'id',
          label: 'Действие',
          class: 'blog-list-action',
        },
      ],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
    }
  },
  computed: {
    modalTitle: {
      get() {
        return this.postId === null
          ? '<h2 class=\'mb-0\'>Создание нового поста</h2>'
          : '<h2 class=\'mb-0\'>Редактирование поста</h2>'
      },
    },
  },
  watch: {
    currentPage: {
      handler: function() {
        this.fetchData()
      },
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    createPost() {
      this.postId = null
      this.$refs['post-modal'].show()
    },
    editPost(id) {
      this.postId = id
      this.$refs['post-modal'].show()
    },
    onClosePostModal(updated) {
      this.$refs['post-modal'].hide()
      if (updated) this.fetchData()
    },
    fetchData() {
      let offset =
        this.currentPage > 0 ? (this.currentPage - 1) * this.perPage : 0
      this.isLoading = true
      this.axios
        .get(
          process.env.VUE_APP_BLOG_BACKEND_URL +
            `/blog/list/${this.perPage}/${offset}`,
          this.$store.getters.getAxiosConfig,
        )
        .then((response) => {
          this.items = response.data.posts
          this.totalItems = response.data.totalItems
          this.isLoading = false
        })
        .catch((error) => {
          console.error(error)
          this.isLoading = false
        })
    },
    datePost(value) {
      if (value) {
        let date = new Date(value)
        return date.toLocaleDateString('ru')
      } else {
        return ''
      }
    },
    delPost(value) {
      this.axios
        .delete(
          process.env.VUE_APP_BLOG_BACKEND_URL + `/blog/post/delete/${value}`,
          this.$store.getters.getAxiosConfig,
        )
        .then((response) => {
          if (response.data.dell_id) {
            this.items = this.items.filter(
              (obj) => obj.id !== response.data.dell_id,
            )
          }
        })
        .catch(function() {
          console.log('FAILURE!!')
        })
    },
  },
}
</script>

<style>
.blog-list-data {
  width: 160px;
}

.blog-list-action {
  width: 90px;
}

.list-post {
  margin-top: 30px;
  width: 100%;
}
</style>
